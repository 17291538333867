/***** BARRE DE SCROLL *****/
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 1);
}
::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(1, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: #065826;
}
.modal::-webkit-scrollbar {
  width: 0;
  background-color: rgba(1, 0, 0, 0);
}

.main-container {
  .container_content {
    &.container_content-loader {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 14px;
      height: calc(100vh - 143px);
      color: #065826;
    }
  }
}

/***** Loader *****/
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  &::after,
  &::before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border: 2px solid var(--color-primary);
    position: absolute;
    left: 0;
    top: 0;
    animation: scaleOut 2s ease-in-out infinite;
  }
  &::after {
    border-color: var(--color-secondary);
    animation-delay: 1s;
  }
}

.Grid {
  background-color: transparent!important;
  padding: 0!important;
  align-items: center;
  &-container {
    padding: 15px 25px 80px 0;
    margin-top: 10px!important;
  }
  &-root {
    margin-top: 0!important;
  }
  &-item {
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
}

.button {
  border: 1px solid #065826;
  color: #065826;
  background-color: transparent;
  padding: 5px 15px;
  border-radius: 4px;
  line-height: 1.75;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    background-color: #065826;
    color: #FFF;
  }
}

#menu-appbar .MuiPaper-root {
  top: 70px!important;
}

.login {
  background: no-repeat url("./images/login-background.jpeg") center;
  background-size: cover;
  position: absolute;
  inset: 0;
  main {
    margin-top: 0 !important;
    background: #fff;
    padding: 20px 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.Grid-checkbox-container {
  .MuiSvgIcon-root {
    width: 20px;
    height: 1em;
  }
}

.Grid-item-parameters {
  padding-left: 0!important;
  &:nth-child(2n) {
    margin-left: 20px;
  }
}

.headerModal {
  padding: 10px 30px;
  background-color: #d8e3e0;
  div {
    h3 {
      font-weight: normal;
      color: #065826;
      margin: 0;
    }
  }
}
.titleModal {
  margin: 10px 0;
  color: #065826;
}
.contentModal {
  padding: 20px 30px;
  color: #065826;
  .MuiSvgIcon-root {
    width: 20px;
    height: 1em;
  }
}
.contentModalArticle {
  padding: 20px 30px 20px 14px!important;
  margin-bottom: 60px;
  .MuiSvgIcon-root {
    width: 20px;
    height: 1em;
  }
}
.footerModal {
  padding: 10px 30px;
  background-color: #d8e3e0;
  text-align: right;
}
.footerModalArticle {
  position: absolute!important;
  bottom: 0;
  background-color: #d8e3e0;
  width: 100%;
  left: 0;
  padding: 10px 20px;
}
.buttonDelete {
  margin-top: 0!important;
  background-color: #065826!important;
  color: #FFF!important;
  &:hover {
  background-color: #04441c!important;
  }
}

/***** Loader *****/
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  &:after, &:before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border: 2px solid #6a707e;
    position: absolute;
    left: 0;
    top: 0;
    animation: scaleOut 2s ease-in-out infinite;
  }
  &:after {
    border-color: #065826;
    animation-delay: 1s;
  }
}

@keyframes scaleOut {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.container_content-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  height: calc(100vh - 143px);
  color: #065826;
}
.currentNameUser {
  font-size: 12px;
  font-family: 'Helvetica', sans-serif;
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.containerLogOut {
  .MuiButtonBase-root {
    border-radius: 0;
    &:hover {
      background-color: transparent;
    }
  }
}
.MuiList{
  padding-top: 0!important;
}

.container_header_member_infos {
  padding-top: 0!important;
}

.alert_logout {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  svg {
    width: 30px!important;
    height: 30px!important;
  }
}

.input-deliveries {
  .MuiFormHelperText-root {
    color: red !important;
  }
}

.typography-bold {
  color: #065826;
  font-weight: bold;
}

.inactive-row {
  background-color: #efd2d2 !important;
}

.small-icon {
  height: 20px !important;
  width: 20px !important;
  vertical-align: text-bottom;
}